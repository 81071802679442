import React, { useEffect, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getConfig } from "../config";
import jwt_decode from "jwt-decode";
import logoPizza from "../assets/logo-pizza48.png";
import logoDemo from "../assets/logo-cic-1.png";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import logoutWithRedirect from "../utils/Logout";

const NavBar = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [accessTokenPermissions, setAccessTokenPermissions] = useState();
  
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  const { organizationIdA, organizationIdB } = getConfig();

  const toggle = () => setIsOpen(!isOpen);

  // const logoutWithRedirect = () =>
  //   logout({
  //     logoutParams: {
  //       returnTo: window.location.origin + "/",
  //       federated: false
  //     }
  //   });

  const navItemsIsntAuthenticated = () => (
    <>
      {getConfig().featureFlagNormalLogin && !isAuthenticated && (
        <NavItem>
          <Button id="qsLoginBtn" color="primary" block className="btn-margin" onClick={() => {
              if(getConfig().featureLoginHint) { loginWithRedirect({authorizationParams: { login_hint: "user001@yoshikazutsujigmail.onmicrosoft.com" }})}
              else { loginWithRedirect() }
            }}
          >
            {t("NavBar.Login")}
          </Button>
        </NavItem>
      )}
      {getConfig().featureFlagPasswordLogin && !isAuthenticated && (
        <NavItem>
          <Button id="qsLoginBtn" color="primary" block className="btn-margin" onClick={() => {
              if(getConfig().featureLoginHint) { loginWithRedirect({ authorizationParams: { login_hint: "user001@yoshikazutsujigmail.onmicrosoft.com", connection: "Username-Password-Authentication" } }) }
              else { loginWithRedirect({ authorizationParams: { connection: "Username-Password-Authentication" }}) }
            }}
          >
            {t("NavBar.LoginByPassword")}
          </Button>
        </NavItem>
      )}
      {getConfig().featureFlagPasswordlessLoginEmail && !isAuthenticated && (
        <NavItem>
          <Button id="qsLoginBtn" color="primary" block className="btn-margin" onClick={() => loginWithRedirect({ authorizationParams: { connection: "email" } })}>
            {t("NavBar.LoginByEmail")}
          </Button>
        </NavItem>
      )}
      {getConfig().featureFlagAzureadLogin && !isAuthenticated && (
        <NavItem>
          <Button id="qsLoginBtn" color="primary" block className="btn-margin" onClick={() => loginWithRedirect({ authorizationParams: { connection: "azuread" } })}>
            {t("NavBar.LoginByAzureAd")}
          </Button>
        </NavItem>
      )}
      {getConfig().featureOrgLogin && !isAuthenticated && (
        <>
          <NavItem>
            <Button id="qsLoginBtn" color="primary" block className="btn-margin" onClick={() => loginWithRedirect({ authorizationParams: { organization: organizationIdA } })}>
              {t("NavBar.LoginAsOrgA")}
            </Button>
          </NavItem>
          <NavItem>
            <Button id="qsLoginBtn" color="primary" block className="btn-margin" onClick={() => loginWithRedirect({ authorizationParams: { organization: organizationIdB } })}>
              {t("NavBar.LoginAsOrgB")}
            </Button>
          </NavItem>
        </>
      )}
    </>
  );

  useEffect(() => {
    (async () => {
      if(isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        if("permissions" in jwt_decode(accessToken)){
          setAccessTokenPermissions(jwt_decode(accessToken)["permissions"]);
        }
      }
    })();
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md">
        <Container>
          <NavbarBrand>
          { getConfig().modePizza42 && (<img className="" src={logoPizza} alt="React logo" width="40" height="40" />)}
          { getConfig().modeSelfDemo && (<img className="" src={logoDemo} alt="React logo" width="36" height="43.6" />)}
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink tag={RouterNavLink} to="/" exact activeClassName="router-link-exact-active">{t("NavBar.Home")}</NavLink>
              </NavItem>

              {getConfig().featureFlagCustomLogin && !isAuthenticated && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/custom-login" exact activeClassName="router-link-exact-active">{t("NavBar.CustomLogin")}</NavLink>
                </NavItem>
              )}

              {getConfig().featureFlagCustomRegister && !isAuthenticated && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/custom-register" exact activeClassName="router-link-exact-active">{t("NavBar.CustomRegister")}</NavLink>
                </NavItem>
              )}

              {getConfig().featureFlagOrderHistory && isAuthenticated && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/order-history" exact activeClassName="router-link-exact-active">{t("NavBar.OrderHistory")}</NavLink>
                </NavItem>
              )}

              {getConfig().featureFlagCommands && isAuthenticated && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/commands" exact activeClassName="router-link-exact-active">{t("NavBar.Command")}</NavLink>
                </NavItem>
              )}

              {getConfig().featureFlagUsers && isAuthenticated && accessTokenPermissions && accessTokenPermissions.includes("admin:pizza42") && (
                <NavItem>
                  <NavLink tag={RouterNavLink} to="/users" exact activeClassName="router-link-exact-active">{t("NavBar.Users")}</NavLink>
                </NavItem>
              )}
            </Nav>

            <Nav className="d-none d-md-block" navbar>
              {navItemsIsntAuthenticated()}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown"><img src={user.picture} alt="Profile" className="nav-user-profile rounded-circle" width="50"/></DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem tag={RouterNavLink} to="/profile" className="dropdown-profile" activeClassName="router-link-exact-active"><FontAwesomeIcon icon="user" className="mr-3" /> {t("NavBar.Profile")}</DropdownItem>
                    <DropdownItem id="qsLogoutBtn" onClick={() => logoutWithRedirect(user, logout)}><FontAwesomeIcon icon="power-off" className="mr-3" /> {t("NavBar.Logout")}</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>

            <Nav className="d-md-none" navbar>
              {navItemsIsntAuthenticated()}
            </Nav>

            {/* {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button id="qsLoginBtn" color="primary" block onClick={() => loginWithRedirect()}>
                    {t("NavBar.Login")}
                  </Button>
                </NavItem>
              </Nav>
            )} */}

            {isAuthenticated && (
              <Nav className="d-md-none justify-content-between" navbar style={{ minHeight: 170 }}>
                <NavItem>
                  <span className="user-info">
                    <img src={user.picture} alt="Profile" className="nav-user-profile d-inline-block rounded-circle mr-3" width="50"/>
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <RouterNavLink to="/profile" activeClassName="router-link-exact-active">{t("NavBar.Profile")}</RouterNavLink>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink to="#" id="qsLogoutBtn" onClick={() => logoutWithRedirect(user, logout)}>{t("NavBar.Logout")}</RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
