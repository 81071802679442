import React, { useState } from 'react';
import { Button } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import {
  DataGrid,
  GridToolbar,
} from '@mui/x-data-grid'

export const UsersComponent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [users, setUsers] = useState({});
  const { apiOrigin, organizationIdA, organizationIdB } = getConfig();

  const callGetAllUsers = async () => {
    console.log("Pizza42: GET /mgmt/users");
    const accessToken = await getAccessTokenSilently()
    const response = await fetch(`${apiOrigin}/mgmt/users?page=0`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    let responseData = await response.json();
    console.log("users:", responseData["users"]);
    setUsers(responseData["users"]);
  }
  const callGetOrgUsers = async (org) => {
    console.log("Pizza42: GET /mgmt/users");
    const accessToken = await getAccessTokenSilently()
    const response = await fetch(`${apiOrigin}/mgmt/users?page=0&org=${org}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    let responseData = await response.json();
    console.log("members:", responseData["members"]);
    setUsers(responseData["members"]);
  }
  const callGetOrgAUsers = async () => {
    callGetOrgUsers(organizationIdA);
  }
  const callGetOrgBUsers = async () => {
    callGetOrgUsers(organizationIdB);
  }
  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  const cols = [
    { field: 'email', headerName: 'メールアドレス', minWidth: 200 },
    { field: 'name', headerName: '名前', minWidth: 150 },
    { field: 'email_verified', headerName: "メールアドレス確認済み", minWidth: 100 },
    { field: 'last_login', headerName: "前回のログイン日時", minWidth: 180 },
    { field: 'last_ip', headerName: "前回のログイン時のIP", minWidth: 100 },
  ]
  const styles = {
    grid: {
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      },
      '.MuiDataGrid-columnHeader': {
        borderRight: 'solid 1px white',
      },
      '.MuiDataGrid-columnHeaders': {
        backgroundColor: '#888888',
        color: '#fff',
      }
    }
  }

  return (
    <>
      <div>
        <h1>Users</h1>
      </div>
      <div className="mb-1">
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetAllUsers)}>Get All Users</Button>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetOrgAUsers)}>Get Org-A Users</Button>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetOrgBUsers)}>Get Org-B Users</Button>
      </div>
      <div style={{ width: '100%' }}>
        <DataGrid
          getRowId={(row) => row.user_id}
          columns={cols}
          // rows={rows}
          rows={users}
          density="compact"
          autoHeight
          components={{
            Toolbar: GridToolbar
          }}
          sx={styles.grid}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
        />
      </div>
      {/*
      <div>
        <h1>Result</h1>
      </div>
      <div className="mb-5">
        <div style={{border: '1px solid #DDDDDD', minHeight: '1rem', padding: '1rem', wordBreak: "break-word"}}>{ apiResult }</div>
      </div>

      <div>
        <h1>Variable</h1>
      </div>
      <div>
        <Table>
          <tbody>
            <tr><td>isAuthenticated</td><td> { isAuthenticated ? "ture": "false" }</td></tr>
            <tr><td>user</td><td> { JSON.stringify(user) }</td></tr>
            <tr><td>Access Token</td><td>{ accessToken }</td></tr>
            <tr><td>ID Token Claims</td><td>{ JSON.stringify(idTokenClaims) }</td></tr>
          </tbody>
        </Table>
      </div> */}
    </>
  );
};

export default withAuthenticationRequired(UsersComponent, {
  onRedirecting: () => <Loading />,
});

