import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import Highlight from "../components/Highlight";

const ProfileUser = () => {
  const { t } = useTranslation();
  const { user } = useAuth0();

  return (
    <>
      <h3>User</h3>
      <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row>
    </>
  )
};

export default withAuthenticationRequired(ProfileUser);