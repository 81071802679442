import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Hero from "../components/Hero";
import Scenarios from "../components/Scenarios";
import Menu from "../components/Menu";
import ProfileUser from "../components/ProfileUser";
import { getConfig } from "../config";

const Home = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      <Hero />
      { getConfig().modePizza42 && (
        <Menu />
      )}
      { !isAuthenticated && getConfig().modeSelfDemo && (
        <Scenarios />
      )}
      { isAuthenticated && getConfig().modeSelfDemo && (
        <ProfileUser />
      )}
    </>
  )
};

export default Home;
