import React, { useState } from "react";
import { getConfig } from "../config";
import auth0 from 'auth0-js'; // for callAuthApiGetUserinfo, WebAuth
import { SHA256, enc } from 'crypto-js';

const CustomLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { domain, clientId, audienceMgmtApi, customLoginConnection } = getConfig();

  const handleChangeEmail = (event) => { setEmail(event.target.value); };
  const handleChangePassword = (event) => { setPassword(event.target.value);};

  const handleSubmit = async(event) => 
  {
    event.preventDefault();

    function encodeBase64URL(string) {
      return string.toString(enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
    }
//    var code_verifier = "iBW92Zt20bo744UMDJc5UlMgvQPKBqMZmQc3lPajAZY";
//    console.log("challenge correct:", "qfXJcChpQ_tVurEUQF3saqGKR33RZrj0F5nxwdHaEFA");

    var state = btoa(String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array(60)))).substring(0,60).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');

    var code_verifier = btoa(String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array(60)))).substring(0,60).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
    console.log("code_verifier: ", code_verifier);

    var code_challenge = encodeBase64URL(SHA256(code_verifier));
    console.log("code_challenge: ", code_challenge)

    sessionStorage.setItem("a0.spajs.txs."+clientId, "{\"code_verifier\":\"" + code_verifier + "\",\"scope\":\"openid profile email create:current_user_order read:current_user_orders\",\"audience\":\"" + audienceMgmtApi + "\",\"redirect_uri\":\"" + window.location.origin + "\",\"state\":\"" + state + "\"}");

    const webAuth = new auth0.WebAuth({  
      domain: domain,
      clientID: clientId,
      responseType: 'token id_token'
    });
    webAuth.login({
      realm: customLoginConnection,
      email: email,
      password: password,
      responseType: 'code',
      redirectUri: window.location.origin,
      state: state,
      code_challenge: code_challenge,
      code_challenge_method: 'S256'
      // redirectUri: 'http://localhost:3000/',
      // prompt: 'login'
      // responseType: 'token id_token'
    }, (err)=> { console.log("ERROR", err)}); 
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>email: <input type="text" value={email} onChange={handleChangeEmail} /></label><br />
      <label>Password: <input type="password" value={password} onChange={handleChangePassword} /></label><br />
      <input type="submit" value="Submit" />
    </form>
  );
}

export default CustomLogin;
