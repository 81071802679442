import React from "react";
import { Row, Col, Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
// import { useTranslation } from "react-i18next";
import { getConfig } from "../config";
// import BtnCopy from "./BtnCopy"
import { useHistory } from 'react-router-dom';

const Scenarios = () => {
  // const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const history = useHistory();

  const scenarios = [
    {
      no: 1,
      title: "パスワード or ソーシャル",
      description: "メールアドレスとパスワード、あるいは Google、Apple、LINE などのソーシャルサービスのアカウントを使ってログインできます。",
      onClick: () => { loginWithRedirect({ authorizationParams: { "ext-scenario": 1 }}); return null; }
    },
    {
      no: 2,
      title: "パスワード or パスキー",
      description: "最新のパスキーを使ってパスワードレスでログインできます。パスワードも利用できるため、最初パスワードでセットアップした上で、後からパスキーを有効化することも可能です。",
      onClick: () => { loginWithRedirect({ authorizationParams: { "ext-scenario": 2, connection: "self-demo-01" }}); return null; }
    },
    {
      no: 3,
      title: "パスワードレス (Email)",
      description: "入力したメールアドレスにワンタイムコードが届き、それを入力することでログインできます。",
      onClick: () => { loginWithRedirect({ authorizationParams: { "ext-scenario": 3, connection: "self-demo-01-email" } }); return null; }
    },
    {
      no: 4,
      title: "パスワードレス (SMS)",
      description: "入力した携帯電話番号にワンタイムコードが届き、それを入力することでログインできます。",
      onClick: () => { loginWithRedirect({ authorizationParams: { "ext-scenario": 4, connection: "self-demo-01-sms" } }); return null; }
    },
    {
      no: 5,
      title: "企業 IdP と連携",
      description: "B2B SaaS で顧客企業が社内で使っている IdP のアカウントを使ってログインできます。ユーザはまずメールアドレスを入力するとその企業の IdP のログイン画面に遷移してログインできます。",
      onClick: () => { loginWithRedirect({ authorizationParams: { "ext-scenario": 5, connection: "self-demo-02" }}); return null; }
    },
    {
      no: 6,
      title: "パスワード & MFA",
      description: "パスワードに加え、Google Authenticator、SMS、セキュリティキー などを使った多要素も使ってログインします。",
      onClick: () => { loginWithRedirect({ authorizationParams: { "ext-scenario": 6, connection: "Username-Password-Authentication" }}); return null; }
    },
    {
      no: 7,
      title: "ボット検知",
      description: "ログインが行われるときに、ボットで対策としてチャレンジを表示します。リスクが高い時にだけ表示することや CAPTCHA を表示するといったこともできます。",
      onClick: () => { loginWithRedirect({ authorizationParams: { "ext-scenario": 7, connection: "self-demo-03" }}); return null; }
    },
    {
      no: 8,
      title: "漏洩パスワード検知",
      description: "サインアップやログインが行われるときに、ユーザが入力したパスワードが漏洩されているものであれば、ユーザにパスワード変更を促します。",
      onClick: () => { loginWithRedirect({ authorizationParams: { "ext-scenario": 8, screen_hint: "signup", connection: "Username-Password-Authentication" }}); return null; }
    },
    {
      no: 9,
      title: "Forms: 規約同意",
      description: "Forms for Actions を使いユーザ登録時に利用規約の同意を求めます。",
      onClick: () => { loginWithRedirect({ authorizationParams: { "ext-scenario": 9, screen_hint: "signup", connection: "Username-Password-Authentication", form: "ap_iGica1JWxJvqfgGqYS96Aw" }}); return null; }
    },
    {
      no: 10,
      title: "Forms: メールアドレス確認待ち",
      description: "Forms for Actions を使いユーザ登録時にそのままログインさせずに、メールアドレスが確認されることを待ちます。",
      onClick: () => { loginWithRedirect({ authorizationParams: { "ext-scenario": 10, screen_hint: "signup", connection: "Username-Password-Authentication", form: "ap_4e1p2y8vjkXt8x3nKeKsiG" }}); return null; }
    }
  ]

  return (
    <>
      {getConfig().modeSelfDemo && !isAuthenticated && (
        <>
          <div>
            <div>
              <Row className="d-flex justify-content-center">
                {
                  scenarios.map((item) =>
                    <Col key={item.no} style={{ minWidth: '20em', maxWidth: '30%', padding: "0em", margin: "1em" }}>
                      <div style={{ border: "3px solid #DDD", padding: "1em" }}>
                        <div style={{ display: "block", top: "0.3em", left: "0.3em", width: "1.4em", height: "1.4em", marginRight: "0.2em", borderRadius: "50%", backgroundColor: "black", color: "white", textAlign: "center" }}>{item.no}</div>
                        <div style={{ padding: "0.1em 0.2em 0.2em 0.5em", fontSize: 'x-large', fontWeight: '900', marginTop: "0.2em" }}>{item.title}</div>
                        <div><p style={{ padding: "0.0em 0.6em", fontSize: 'small', marginTop: "1em" }}>{item.description}</p></div>
                        <div style={{ textAlign: "right" }}><Button id="qsLoginBtn" color="primary" style={{padding: "0.4em 0.4em", width: "100%", verticalAlign: "baseline"}} onClick={ item.onClick }>ログイン</Button></div>
                      </div>
                    </Col>
                  )
                }
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  )
};

export default Scenarios;
