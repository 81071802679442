import React, { useState } from "react";
import { Button, Table } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";

export const OrderHistoryComponent = () => {
  const { apiOrigin } = getConfig();

  const [state, setState] = useState({
    showResult: false,
    apiMessage: null,
    error: null,
  });

  const {
    getAccessTokenSilently,
  } = useAuth0();

  const callApi = async () => {
    try {
      let accessToken = null;
      try {
        console.log("call getAccessTokenSilently(audience: \"" + apiOrigin + "/\")");
        accessToken = await getAccessTokenSilently({authorizationParams: {
          audience: apiOrigin + "/"
        }});
      } catch (error) {
        console.error(error);
        return
      }
      console.log("accessToken:", accessToken);

      console.log(`GET ${apiOrigin}/orders`);
      const response = await fetch(`${apiOrigin}/orders`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
      
        console.log("responseData:", responseData);
        setState({
          ...state,
          showResult: true,
          apiMessage: responseData,
        });
      } else {
        setState({
          ...state,
          error: "Error",
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error ?? "Error",
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  // このチェックだと、トークン取るのに画面遷移した時に無限ループする
  if(state && !state.apiMessage && !state.error) {
    callApi();
  }

  console.log(state);
  return (
    <>
      { state.showResult && (
        <>
          <div className="mb-4" style={{fontSize:"large"}}>Total { state.apiMessage.length } orders</div>
          <Table>
            <thead>
              <tr><th>Date</th><th>Pizza Name</th></tr>
            </thead>
            <tbody>
              {
                state.apiMessage.map((order) =>
                  <tr key={order.SK}>
                    <td>{
                    new Date(Date.parse(order.SK)).toLocaleString()
                    }</td>
                    <td>{order.PizzaName}</td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </>
      )}
      {
        !state.showResult && (
          <>
            <h3 className="m-4 text-center">No orders</h3>
          </>
        )
      }
      <div className="text-right">
        <Button color="primary" onClick={(e) => handle(e, callApi)}>Refresh</Button>
      </div>
    </>
  );
};

export default withAuthenticationRequired(OrderHistoryComponent, {
  onRedirecting: () => <Loading />,
});
