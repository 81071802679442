import React, { useEffect, useState } from "react";
import { Router, Route, Switch, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import OrderHistory from "./views/OrderHistory";
import Commands from "./views/Commands";
import Users from "./views/Users";
import CustomLogin from "./views/CustomLogin";
import CustomRegister from "./views/CustomRegister";
import Test from "./views/Test";
import PrivacyPolicy from "./views/PrivacyPolicy";
import history from "./utils/history";
import Configure from "./views/Configure";
import { getConfig } from "./config";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error, loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    // set favicon dynamically
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.rel = 'icon';
    if(getConfig().modePizza42) {
      link.href = '/favicon-pizza48.png';
      link.type = 'image/png';
    } else {
      link.href = '/favicon-cic.svg';
      link.type = 'image/svg+xml';
    }
    document.getElementsByTagName('head')[0].appendChild(link);
  }, []);

  if (error) {
    console.log(error);
    return <div>Auth0 Error: {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <InnterAppForGa>
        <title>
          { getConfig().modePizza42 && ( <>Pizza 48</> ) }
          { getConfig().modeSelfDemo && ( <>Okta CIC Demo</> ) }
          { getConfig().modeSelfDemo && isAuthenticated &&  ("scenario" in user) && ( <> | Scenario { user.scenario }</> ) }
          { getConfig().modeSelfDemo && isAuthenticated && !("scenario" in user) && ( <> | Loggedin</> ) }
        </title>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/profile" component={Profile} />
              <Route path="/order-history" component={OrderHistory} />
              <Route path="/commands" component={Commands} />
              <Route path="/users" component={Users} />
              <Route path="/test" component={Test} />
              <Route path="/pp" component={PrivacyPolicy} />
              <Route path="/custom-login" component={CustomLogin} />
              <Route path="/custom-register" component={CustomRegister} />
              <Route path="/login" component={() => {
                var params = {};
                if (query.get('form') || query.get('ext-scenario')) {
                  if (query.get('form')) { params['form'] = query.get('form');}
                  if (query.get('ext-scenario')) { params['ext-scenario'] = query.get('ext-scenario'); }
                  loginWithRedirect({ authorizationParams: params }); return null;
                } else {
                  loginWithRedirect(); return null;
                }
              }} />
              <Route path="/login-email" component={() => { loginWithRedirect({ authorizationParams: { connection: "email" } }); return null; }} />
              <Route path="/logout" component={() => { logout({ logoutParams: { returnTo: window.location.origin + "/" } }); return null; }} />
              <Route path="/invite" component={() => {
                var params = {};
                if (query.get('organization')) {
                  params['organization'] = query.get('organization');
                }
                if (query.get('invitation')) {
                  params['invitation'] = query.get('invitation');
                }
                console.log(params);
                loginWithRedirect({ authorizationParams: params }); return null;
              }} />
              <Route path="/configure" component={Configure} />
              {/* <Route path="/scenario-1"><Loading component={() => { loginWithRedirect({ authorizationParams: { "ext-scenario": 1 }}); return null; }}/></Route>
              <Route path="/scenario-2"><Loading component={() => { loginWithRedirect({ authorizationParams: { "ext-scenario": 2 }}); return null; }}/></Route>
              <Route path="/scenario-3"><Loading component={() => { loginWithRedirect({ authorizationParams: { "ext-scenario": 3, connection: "email" } }); return null; }}/></Route>
              <Route path="/scenario-4"><Loading component={() => { loginWithRedirect({ authorizationParams: { "ext-scenario": 4, connection: "sms" } }); return null; }}/></Route>
              <Route path="/scenario-5"><Loading component={() => { loginWithRedirect({ authorizationParams: { "ext-scenario": 5 }}); return null; }}/></Route>
              <Route path="/scenario-6"><Loading component={() => { loginWithRedirect({ authorizationParams: { "ext-scenario": 6, connection: "Username-Password-Authentication" }}); return null; }}/></Route>
              <Route path="/scenario-7"><Loading component={() => { loginWithRedirect({ authorizationParams: { "ext-scenario": 7 }}); return null; }}/></Route>
              <Route path="/scenario-8"><Loading component={() => { loginWithRedirect({ authorizationParams: { "ext-scenario": 8, screen_hint: "signup" }}); return null; }}/></Route> */}
            </Switch>
          </Container>
          <Footer />
        </div>
      </InnterAppForGa>
    </Router>
  );
};

function InnterAppForGa({ children }) {
  const { listen } = useHistory()
 
  useEffect(() => {
   const unlisten = listen((location) => {
     if (!window.gtag) return
     window.gtag('config', 'G-5TR0BRZHNT', { page_path: location.pathname })
   })
   return unlisten
  }, [listen])

  return <>{ children }</>
}

export default App;
