import React from 'react';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";

export const CommandsComponent = () => {
  return (
    <>
      <div>
        <h1>Test</h1>
      </div>
      <div>
      Test Page
      </div>
    </>
  );
};

export default withAuthenticationRequired(CommandsComponent, {
  onRedirecting: () => <Loading />,
});

