import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";

const { apiOrigin } = getConfig();

const Menu = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [pizzasList, setPizzasList] = useState([]);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showOrderErrorAlert, setShowOrderErrorAlert] = useState(false);
  const [showOrderSuccessAlert, setShowOrderSuccessAlert] = useState(false);
  const [OrderErrorAlertMessage, setOrderErrorAlertMessage] = useState(null);
  const [choicedPizzaName, setChoicedPizzaName] = useState(null);

  useEffect(async () => {
    // getPizzaList
    try {
      const response = await fetch(`${apiOrigin}/pizzas`);
      if (!response.ok) { return }
      const responseData = await response.json();
      console.log("GET /pizzas responseData:", responseData);
      setPizzasList(responseData);
    } catch (error) {
      console.error(error);
    }
  },[]);

  const handleOrder = (name) => {
    console.log("handleOrder()", name);
    postOrder(name)
  };
  const handleOrderModalOpen = (name) => {
    console.log("handleOrderModalOpen(" + name + ")");
    setChoicedPizzaName(name);
    setShowOrderModal(true);
  };
  const handleOrderModalClose = () => {
    console.log("handleOrderModalClose()");
    setShowOrderModal(false);
    setChoicedPizzaName(null);    
  };
  const handleAlertOrderError = (message) => {
    console.log("handleAlertOrderError");
    setOrderErrorAlertMessage(message);
    setShowOrderErrorAlert(true);

    setTimeout(() => {
      setShowOrderErrorAlert(false);
    }, 3000);
  };
  const handleAlertOrderSuccess = () => {
    console.log("handleAlertOrderSuccess");
    setShowOrderSuccessAlert(true);

    setTimeout(() => {
      setShowOrderSuccessAlert(false);
    }, 3000);
  };
  const handleOrderErrorAlertToggle = () => {
    console.log("handleOrderErrorAlertToggle");
    setShowOrderErrorAlert(!showOrderErrorAlert);
  };
  const handleOrderSuccessAlertToggle= () => {
    console.log("handleOrderSuccessAlertToggle");
    setShowOrderSuccessAlert(!showOrderSuccessAlert);
  }

  const postOrder = async (name) => {
    try {
      let accessToken = null;
      try {
        accessToken = await getAccessTokenSilently({ audience: apiOrigin + "/" });
      } catch (error) {
        console.error(error);
        handleAlertOrderError("Failed to get access token");
        return
      }
      console.log("accessToken:", accessToken);

      const response = await fetch(`${apiOrigin}/orders`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ "PizzaName": name })
      });
      console.log("response:", response);

      if (response && response.status === 200) {
        handleAlertOrderSuccess();
        handleOrderModalClose();
      } else {
        handleAlertOrderError(response.statusText ? response.statusText : "Failed to call");
      }
    } catch (error) {
      console.error(error);
      handleAlertOrderError("Failed to call");
    }
  };

  let button_text = "Order Now";
  if (!isAuthenticated) { button_text = "Need to Login" };
  if (isAuthenticated && !user.email_verified) { button_text = "Need to Verify email" };
  const choicedPizza = pizzasList.find((v) => v.PK === choicedPizzaName)

  if (!getConfig().modePizza42) { return (<></>) }
  return (
    <>
      <hr />
      <Modal isOpen={showOrderModal} toggle={handleOrderModalClose}>
        <ModalHeader toggle={handleOrderModalClose}>
          <span style={{ fontSize: 'x-large', fontWight: '900' }}>{choicedPizza ? choicedPizza.PK : "---"}</span>
        </ModalHeader>
        <ModalBody>
          {choicedPizza && (<img src={choicedPizza.Photo} alt={choicedPizza.PK} />)}
          <span style={{ marginLeft: '1em', fontSize: 'xxx-large', fontWight: '600' }}>${choicedPizza ? choicedPizza.Price : "---"}</span>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleOrderModalClose}>Cancel</Button>{' '}
          <Button color="primary" onClick={() => handleOrder(choicedPizzaName)} id="ButtonOrder">Order</Button>
        </ModalFooter>
      </Modal>
      <Alert color="danger" isOpen={showOrderErrorAlert} toggle={handleOrderErrorAlertToggle} style={{ position: "fixed", zIndex: "1051", right: "2em", top: "2em" }}>
        Error: {OrderErrorAlertMessage}
      </Alert>
      <Alert color="success" isOpen={showOrderSuccessAlert} toggle={handleOrderSuccessAlertToggle} style={{ position: "fixed", zIndex: "1051", right: "2em", top: "2em" }}>
        Successfully ordered.
      </Alert>

      <div>
        <h2 className="my-5 text-center">Online Menu</h2>
        <div className="result-block-container">
          <Row className="d-flex justify-content-between">
            {
              pizzasList.map((pizza) =>
                <Col key={pizza.PK}>
                  <div style={{ position: 'relative' }}>
                    <img src={pizza.Photo} alt={pizza.PK} />
                    <Button className="order" onClick={() => handleOrderModalOpen(pizza.PK)} disabled={isAuthenticated && user.email_verified ? false : true}>{button_text}</Button>
                  </div>
                  <div>
                    <span style={{ fontSize: 'x-large', fontWeight: '900' }}>{pizza.PK}</span>
                    <span style={{ marginLeft: '1em', fontSize: 'large', fontWeight: '600' }}>${pizza.Price}</span>
                  </div>
                  <p>{pizza.Description}</p>
                </Col>
              )
            }
          </Row>
        </div>
      </div>
    </>
  );

};

export default Menu;