import React from "react";
import { Container } from "reactstrap";

import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ProfileName from "../components/ProfileName";
import ProfileUser from "../components/ProfileUser";
import ProfileToken from "../components/ProfileToken";

export const ProfileComponent = () => {
  return (
    <Container className="mt-4">
      <ProfileName />
      <ProfileUser />
      <ProfileToken />
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
