import configJson from "./auth_config.json";

export function getConfig() {
  const origin = window.location.origin;
  let latest_config = {}

  // Set default value 'null' for mandatory parameters
  latest_config.apiOrigin = null;
  latest_config.audience = null;
  latest_config.audienceMgmtApi = null;
  latest_config.audienceMfaApi = null;
  latest_config.domain = null;
  latest_config.domainMgmt = null;
  latest_config.organizationIdA = null;
  latest_config.organizationIdB = null;
  latest_config.parameter_custom_key = null;
  latest_config.parameter_custom_value = null;

  // Set default values
  latest_config.version = "2024-08-21 v2"

  latest_config.modeSelfDemo = false;
  latest_config.modePizza42 = true;

  latest_config.featureFlagCustomLogin = false;
  latest_config.featureFlagCustomRegister = false;
  latest_config.featureFlagOrderHistory = true;
  latest_config.featureFlagCommands = true; // default true
  latest_config.featureFlagUsers = false;
  latest_config.featureFlagNormalLogin = true; // default true
  latest_config.featureFlagPasswordLogin = false;
  latest_config.featureFlagPasswordlessLoginEmail = false;
  latest_config.featureFlagAzureadLogin = false;
  latest_config.featureOrgLogin = false;
  latest_config.featureLoginHint = false;

  latest_config.parameter_custom_key = "dummy";
  latest_config.parameter_custom_value = true;

  latest_config.customLoginConnection = "Username-Password-Authentication";
  latest_config.useRefreshTokens = false;
  latest_config.cacheLocation = "memory"; // or localstorage
  latest_config.useCookiesForTransactions = false;
  latest_config.scope = "";

  // console.log(latest_config);

  // OVerwrite with auth_config.json
  if (origin in configJson) {
    Object.keys(configJson[origin]).forEach((key) => {
      latest_config[key] = configJson[origin][key];
    });
  } else {
    Object.keys(configJson["default"]).forEach((key) => {
      latest_config[key] = configJson["default"][key];
    });
  }
  
  // Overwrite with LocalStorage
  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    let value = localStorage.getItem(key);

    if(key.startsWith("pizza42.")) {
      if(value === "false") { value = false; }
      if(value === "true") { value = true; }
      latest_config[key.substring("pizza42.".length)] = value;
    }
  }

  // Add scope 'offline_access' for RefreshToken
  if(latest_config.useRefreshTokens === true && latest_config.scope.indexOf("offline_access") === -1) {
    latest_config.scope = "offline_access " + latest_config.scope;
  }

  // console.log(latest_config);
  return latest_config;
}
