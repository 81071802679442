import React from "react";
import logoPizza from "../assets/logo-pizza.png";
import logoDemo from "../assets/logo-cic-2.png"
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import { Button } from "reactstrap";
import logoutWithRedirect from "../utils/Logout";

const Hero = () => {
  const { t } = useTranslation();
  const { isAuthenticated, logout, user } = useAuth0();

  // const logoutWithRedirect = () => {
  //   if(user.scenario === "2" || user.scenario === "3" || user.scenario === "4") {
  //     window.location.href = "https://self-demo-01-auth-p.authlab.xyz/v2/logout?client_id=pQWASUdkVwB8UPumfwMjp8Eb4yO8PQSW&returnTo=https://self-demo.authlab.xyz/logout";
  //   }
  //   else if (user.scenario === "5") {
  //     window.location.href = "https://self-demo-02-auth-p.authlab.xyz/v2/logout?client_id=PWhwnEuiPcQQvq1id6JbJuMzwJXOAHsT&returnTo=https://self-demo.authlab.xyz/logout";
  //   }
  //   else if (user.scenario === "7") {
  //     window.location.href = "https://self-demo-03-auth-p.authlab.xyz/v2/logout?client_id=yfQg3Z77Ve49chQ86iWbPXdERpDmybcZ&returnTo=https://self-demo.authlab.xyz/logout";
  //   }
  //   else {
  //     console.log("Federated Logout");
  //     logout({
  //       logoutParams: {
  //         returnTo: window.location.origin + "/",
  //         federated: false
  //       }
  //     });
  //    }
  // }
 
  return (
    <div className="hero">
      { getConfig().modePizza42 && (
        <>
          <img className="mb-3 img-fill" src={logoPizza} alt="React logo" width="400" height="176" />
          <h1 className="mb-4">Pizza 42</h1>
          <p className="message">{t("Hero.Message")}</p>
        </>
      )}
      { getConfig().modeSelfDemo && !isAuthenticated && (
        <>
          <img className="mb-3 img-fill" src={logoDemo} alt="React logo" width="400" height="176" />
          <h1 className="mb-4">デモ Web サイト</h1>
          <p className="message">Okta Customer Identity Cloud のログインを実際に操作して体験頂ける Web サイトです。</p>
        </>
      )}
      { getConfig().modeSelfDemo && isAuthenticated && (
        <>
          <h1 className="mb-4">デモ Web サイト</h1>
          <p className="message"><Button id="qsLoginBtn" color="primary" style={{padding: "0.1em 0.4em", verticalAlign: "baseline"}} onClick={() => logoutWithRedirect(user, logout) }>ログアウト</Button> すると他のシナリオを試して頂けます。</p>
        </>
      )}
    </div>
  )
};

export default Hero;
