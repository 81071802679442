const logoutWithRedirect = (user, logout) => {

  if(user.scenario === "2" || user.scenario === "3" || user.scenario === "4") {
    window.location.href = "https://self-demo-01-auth-p.authlab.xyz/v2/logout?client_id=SRlg3yfni1iSPz9YI8c9QckTpINbvVdT&returnTo=" + window.location.origin + "/logout";
  }
  else if (user.scenario === "5") {
    window.location.href = "https://self-demo-02-auth-p.authlab.xyz/v2/logout?client_id=Urh8zKIXeirFiiEwBFpkOL59I3oAG65g&returnTo=" + window.location.origin + "/logout";
  }
  else if (user.scenario === "7") {
    window.location.href = "https://self-demo-03-auth-p.authlab.xyz/v2/logout?client_id=3VCMrId9v6i9gu0QIgood1lotta4fwZV&returnTo=" + window.location.origin + "/logout";
  }
  else {
    console.log("Federated Logout");
    logout({
      logoutParams: {
        returnTo: window.location.origin + "/",
        federated: false
      }
    });
    }
}

export default logoutWithRedirect;
