import React from "react";
import { Container } from "reactstrap";

const PrivacyPolicy = () => (
  <Container className="mt-4">
    <h1>Privacy Policy</h1>
    <div className="mt-4">
        <ul>
            <li>本 Web サイトは Okta Customer Identity Cloud が提供するログイン機能を試すデモや検証を目的としています。</li>
            <li>登録されたユーザ情報は Okta Customer Identity Cloud に保存されます。</li>
            <li>ユーザ登録された情報は本 Web サイトの動作として必要な用途以外で使用しません。利用に応じて、登録されたメールアドレスや携帯番号をパスワードレスログイン、多要素認証 (MFA)、メールアドレス確認等のためにメールや SMS のメッセージを送ることがあります。メールや SMS の送信には Okta Customer Identity Cloud に加え、Amazon Web Services を利用します。</li>
            <li>原則として、テスト用のメールアドレス、テスト用の外部サービスのアカウントでログインして下さい。個人情報の登録、個人情報が含まれている外部サービスでのログインは行わないでください。</li>
            <li>LINE にメールアドレスをリクエストして取得しますが、本アプリでの ID として利用し、通知メールの送信先として利用することがあります。</li>
            <li>Okta Custmer Identity Cloud に保存されたユーザ情報は登録後 15-20 分程度経過後、自動的に削除します。</li>
            <li>Google Analytics を使ったアクセス情報を収集し、分析を行います。</li>
        </ul>
    </div>
  </Container>
);

export default PrivacyPolicy;
