import React, { useEffect } from "react";
import { ReactComponent as MySVGImage } from "../assets/loading_.svg";

const Loading = (props) => {
  useEffect(async () => {
    if( props.component ){
      props.component();
      // const id = setInterval(() => {
      //   props.component();
      // }, 500);
      // return () => clearInterval(id);
    }
  }, []);

  return (
    <div className="spinner">
      <MySVGImage style={{width:"auto", width:"auto"}} />
    </div>
  )
};

export default Loading;
