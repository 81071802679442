import React, { useState, useEffect } from 'react';
import { Table, Button } from "reactstrap";
import { getConfig } from "../config";

const Configure = () => {
  const [items, setItems] = useState({});
  const [formKey, setFormKey] = useState("");
  const [formValue, setFormValue] = useState("");
  const configs = getConfig();

  const handleChangeFormKey = (event) => { setFormKey(event.target.value); };
  const handleChangeFormValue = (event) => { setFormValue(event.target.value); };
  const config_key_prefix = "pizza42."

  useEffect(() => {
    realodLocalStorage();
  }, []);

  const realodLocalStorage = () => {
    let newItems = {};
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      let value = localStorage.getItem(key);

      if(key.startsWith(config_key_prefix)) {
        newItems[key.substring(config_key_prefix.length)] = value;
      }
    }
    setItems(newItems);
  }

  const handleSubmit = async(event) => 
  {
    event.preventDefault();
    localStorage.setItem(config_key_prefix + formKey, formValue);
    //    realodLocalStorage();
    window.location.reload(); // state管理されていない getConfig() を反映させるためには reload が必要
}

  const handleClickDelete = async(key) =>
  {
    localStorage.removeItem(config_key_prefix + key);
//    realodLocalStorage();
    window.location.reload();
  }

  return (
    <>
      <h1 className="mt-4 mb-2">Configure</h1>
      <section>
        <h2 className="mt-4 mb-2">Add Configurations in LocalStorage</h2>
        <form onSubmit={handleSubmit}>
          <label>Key: <input type="text" value={formKey} onChange={handleChangeFormKey} /></label><br />
          <label>Value: <input type="text" value={formValue} onChange={handleChangeFormValue} /></label><br />
          <input type="submit" value="Submit" />
        </form>
      </section>
      <section>
        <h2 className="mt-4">Show Configurations in LocalStorage</h2>
        <Table>
          <thead>
            <tr><th>Key</th><th>Value</th><th>Actions</th></tr>
          </thead>
          <tbody>
          {
            Object.keys(items).map((key) =>
                <tr key={key}>
                  <td>{key}</td>
                  <td>{key in items? items[key] : "n/a"}</td>
                  <td><Button onClick={() => handleClickDelete(key)}>Delete</Button></td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </section>
      <section>
        <h2 className="mt-4">Show Configurations by getConfig()</h2>
        <Table>
          <thead>
            <tr><th>Key</th><th>Value</th></tr>
          </thead>
          <tbody>
          {
            Object.keys(configs).map((key) => 
                <tr key={key}>
                  <td>{key}</td>
                  <td>{ typeof(configs[key]) === "string" ? "\"" + configs[key] + "\"" : configs[key]!=null? configs[key].toString():"null" }</td>
                </tr>
              )
          }
          </tbody>
        </Table>
      </section>
    </>
  );
}

export default Configure;
