import React, { useState } from "react";
import { getConfig } from "../config";
import auth0 from 'auth0-js'; // for callAuthApiGetUserinfo, WebAuth
import { SHA256, enc } from 'crypto-js';

const CustomRegister = () => {
  const [familyName, setFamilyName] = useState("オクタ");
  const [givenName, setGivenName] = useState("太郎");
  const [address, setAddress] = useState("〒150-8510 東京都渋谷区渋谷2-21-1 渋谷ヒカリエ30階");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("")
  const { domain, clientId, apiOrigin, audienceMgmtApi } = getConfig();

  const handleChangeFamilyName = (event) => { setFamilyName(event.target.value); };
  const handleChangeGivenName = (event) => { setGivenName(event.target.value); };
  const handleChangeAddress = (event) => { setAddress(event.target.value); };
  const handleChangeEmail = (event) => { setEmail(event.target.value); };
  const handleChangePassword = (event) => { setPassword(event.target.value);};
  const handleChangeUsername = (event) => { setUsername(event.target.value);};

  const handleSubmit = async(event) => 
  {
    event.preventDefault();

    function encodeBase64URL(string) {
      return string.toString(enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
    }
//    var code_verifier = "iBW92Zt20bo744UMDJc5UlMgvQPKBqMZmQc3lPajAZY";
//    console.log("challenge correct:", "qfXJcChpQ_tVurEUQF3saqGKR33RZrj0F5nxwdHaEFA");

    var state = btoa(String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array(60)))).substring(0,60).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');

    var code_verifier = btoa(String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array(60)))).substring(0,60).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
    console.log("code_verifier: ", code_verifier);

    var code_challenge = encodeBase64URL(SHA256(code_verifier));
    console.log("code_challenge: ", code_challenge)

    console.log("Pizza42: POST /mgmt/users");
    const response = await fetch(`${apiOrigin}/mgmt/users`, {
      method: "POST",
      body: JSON.stringify({
        family_name: familyName,
        given_name: givenName,
        address: address,
        email: email,
        password: password,
        username: username
      })
    });
    const responseOk = await response.ok;
    const responseData = await response.json();
    if(!responseOk) {
      console.log("Filed to create the user.")
      console.log("responseData:", responseData);
    } else {
      console.log("The user was created.")
      console.log("responseData:", responseData);
    }

    sessionStorage.setItem("a0.spajs.txs."+clientId, "{\"code_verifier\":\"" + code_verifier + "\",\"scope\":\"openid profile email create:current_user_order read:current_user_orders\",\"audience\":\"" + audienceMgmtApi + "\",\"redirect_uri\":\"" + window.location.origin + "\",\"state\":\"" + state + "\"}");

    const webAuth = new auth0.WebAuth({  
      domain: domain,
      clientID: clientId,
      responseType: 'token id_token'
    });

    if(email !== ""){
      webAuth.login({
        realm: 'Username-Password-Authentication',
        email: email,
        password: password,
        responseType: 'code',
        redirectUri: window.location.origin,
        state: state,
        code_challenge: code_challenge,
        code_challenge_method: 'S256'
        // redirectUri: 'http://localhost:3000/',
        // prompt: 'login'
        // responseType: 'token id_token'
      }, (err)=> { console.log("ERROR", err)}); 
    } else {
      webAuth.login({
        realm: 'Username-Password-Authentication',
        username: username,
        password: password,
        responseType: 'code',
        redirectUri: window.location.origin,
        state: state,
        code_challenge: code_challenge,
        code_challenge_method: 'S256'
        // redirectUri: 'http://localhost:3000/',
        // prompt: 'login'
        // responseType: 'token id_token'
      }, (err)=> { console.log("ERROR", err)}); 
    }
  }

  return (
    <>
      <div className="mb-5">
        <h2>メールアドレス＋パスワード＋α</h2>
        <form onSubmit={handleSubmit}>
          <label>Family Name: <input type="text" value={familyName} onChange={handleChangeFamilyName} /></label><br />
          <label>Given Name: <input type="text" value={givenName} onChange={handleChangeGivenName} /></label><br />
          <label>Address: <input type="text" value={address} onChange={handleChangeAddress} /></label><br />

          <label>email: <input type="text" value={email} onChange={handleChangeEmail} /></label><br />
          <label>Password: <input type="password" value={password} onChange={handleChangePassword} /></label><br />
          <input type="submit" value="Submit" />
        </form>
      </div><div>
        <h2>ユーザ名＋パスワード (without メールアドレス)</h2>
        <form onSubmit={handleSubmit}>
          <label>username: <input type="text" value={username} onChange={handleChangeUsername} /></label><br />
          <label>Password: <input type="password" value={password} onChange={handleChangePassword} /></label><br />
          <input type="submit" value="Submit" />
        </form>
      </div>
    </>
  );
}

export default CustomRegister;
