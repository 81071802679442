import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import "./i18n/config";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    clientId={config.clientId}
    onRedirectCallback={onRedirectCallback}
    cacheLocation={config.cacheLocation}
    useRefreshTokens={config.useRefreshTokens}  // use with settings: scope +=" offline_access" for Auth0Provider, Enable "Allow Offline Access" for API, Enable "OIDC conformant" for App, Use cacheLocation="localstorage" & useRefreshTokens={true} for Auth0Provider
    useRefreshTokensFallback={true}  // This is needed to get token silently with another audience (Unknown reason)
    useCookiesForTransactions={config.useCookiesForTransactions} // for LINE login on iPhone Safari
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: config.audience,
      // scope: config.scope, // for Silent Auth
      scope: config.scope,
    }}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
