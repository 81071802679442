import React from "react";

const Footer = () => (
  <footer className="bg-light">
    <p>
      Sample project provided by <a href="https://www.okta.com/">Okta</a><br />
      <a href="/pp">Privacy Policy</a>
    </p>
  </footer>
);

export default Footer;
